<template>

  <div class="mt-1">

    <Menu></Menu>
    <Slider></Slider>

    <div class="body">

      <div class="table-responsive">
        <button style="margin-top: 2em;" class="btn btn-primary text-white" @click="print">Print</button>
        <table class="table">
          <thead class=" text-primary text-center">
            <tr>
              <th rowspan="2">TIME</th>
              <th rowspan="2">GAME ID</th>
              <th rowspan="2">TEAMS</th>
              <th :colspan="outcomes.length" v-for="h in outcome" :key="h">{{h.market_name}}</th>
            </tr>
            <tr>
              <th v-for=" i in outcomes" :key="i">{{i}}</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="f in odds" :key="f">
            <td>
              {{f.time}}
            </td>
            <td>
              {{f.game_id}}
            </td>
            <td>
              {{f.name}}
            </td>
            <td v-for="o in f.odds" :key="o">
              {{o}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>

</template>

<script>

import Slider from './Slider'

import axios from "@/services/api";
import Menu from "./Menu";

export default {
  name: 'Booklet',
  metaInfo: {
    title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
    meta: [
      { name: 'description', content: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!' },
      {
        name: 'keywords', content: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting,' +
          'Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In- play betting, Cash out, Live odds, Betting tips, Aviator,' +
          'Jetx, Cometcrash, Spin2wiin, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support,' +
          'Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score'
      }
    ]
  },
  components: {
    Menu,
    Slider
  },
  data: function () {
    return {
      odds: [],
      fixtures: [],
      headers: [],
      outcomes: []
    }
  },
  methods: {
    getBook: function () {
      var vm = this;
      var path = process.env.VUE_APP_URL_BOOKLET.replace("{sport_id}",1);

      axios.post(path,JSON.stringify({}))
              .then(res => {

                vm.busy = false;

                vm.fixtures = res.data.message.fixtures;
                vm.headers = res.data.message.headers;

                var outcomes = [];

                vm.jQuery.each(vm.headers,function(k,v){

                  vm.jQuery.each(v.outcomes,function(kk,vv){

                    outcomes.push(vv);

                  })

                });

                this.outcomes = outcomes;

                vm.jQuery.each(vm.fixtures,function(k,v){

                  var odds = [];

                  vm.jQuery.each(v.markets,function(kk,vv){

                    vm.jQuery.each(vv.Odds,function(kkk,vvv){

                      odds.push(vvv);

                    })

                  })

                  v.odds = odds;
                  vm.fixtures[k] = v;

                });
                this.odds = vm.fixtures;

              })
              .catch(error => {

                console.log(error)

              })
    },

    print: function () {
      return window.print();
    }
  },
  mounted: function () {
    this.getBook()
  },
  computed: {
    outcome: function () {
      return this.headers;
    },
    fixture: function () {
      return this.fixture();
    },

  }
}
</script>

<style scoped>
  table {
    text-indent: initial;
    white-space: normal;
    line-height: normal;
    font-weight: normal;
    font-size: medium;
    font-style: normal;
    text-align: start;
    border-spacing: 2px;
    font-variant: normal;
  }

  .table-responsive table {
    width: 100%;
    box-sizing: border-box;
    border-left: #ccc solid 1px;
    border-right: #ccc solid 1px;
  }

  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr{
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  .table-responsive table tr td:nth-child(1), .table-responsive table tr td:nth-child(2), .table-responsive table tr td:nth-child(3){
    font-weight: 400;
  }

  .table-responsive table tr td:nth-child(1), .table-responsive table tr td:nth-child(2), .table-responsive table tr td:nth-child(3){
    border-right: #bbb solid 1px;
  }
  .table-responsive table tr:nth-of-type(odd) td {
    background: #eee;
  }
  .table-responsive table tr th {
    display: table-cell;
    font-size: 2.5mm;
    color: #fff;
    background: #555;
    padding: 1mm 2.0mm;
    border-right: #ccc solid 1px;
    border-bottom: #ccc solid 1px;
  }

  .table-responsive table tr th:last-child {
    border-right: none;
  }

  .table-responsive table tr td {
    background: #fff;
    color: #000;
    padding: .8mm 1.5mm;
    font-size: 2.54mm;
    text-align: center;
    border-bottom: #ccc solid 1px;
    white-space: nowrap;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 32mm;
  }
</style>